import { Attributes } from '../types/attribute.type';
import Layer from '../types/layer.type';
import Template, { View } from '../types/template.type';
import { getTemplateData } from './data.helpers';

class FeedMappingHelpers {
    /**
     * Check if the layer and attribute are linked to the input.
     * @param feedMapping - The feed mapping object from the template.
     * @param layer - The layer.
     * @param attribute - The attribute from the input.
     * @returns - Returns true if the layer and attribute are linked to the input.
     */
    static isLinkedToInput = (feedMapping: Template['dataVariables']['frameType'], layerKey: Layer['key'], attribute: Attributes): boolean => {
        if (!feedMapping || !layerKey) return false;
        const feedMappingKeys = Object.keys(feedMapping);
        const feedMappingKeyExists = feedMappingKeys.includes(layerKey);
        const feedMappingAttributeExists = feedMapping[layerKey] && Object.keys(feedMapping[layerKey]).includes(attribute);
        return feedMappingKeyExists && feedMappingAttributeExists;
    };

    /**
     * Checks if the frame has feedmapping.
     * @param frameType - The frame type to get the linked inputs for.
     * @returns If the frame has feedmapping
     */
    static frameHasFeedMapping = (frameType?: View['frameType']): boolean => {
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const feedMapping = getTemplateData<Template['dataVariables']>('dataVariables');
        const currentFeedMapping = feedMapping[frameType];

        return !!(currentFeedMapping && !!Object.keys(currentFeedMapping).length);
    };
}

export { FeedMappingHelpers };
