import cloneDeep from 'components/template-designer/utils/cloneDeep';
import Translation from 'components/data/Translation';
import FrameType from '../types/frameTypes.type';
import Template, { State, TemplateData, View } from '../types/template.type';
import { getTemplateData } from './data.helpers';
import Layer from '../types/layer.type';
import { defaultFormatProperties } from '../config/layer-properties/default-format-properties';
import { generateKey } from '../utils/generateKey';
import { TimelineHelpers } from './timeline.helpers';
import TemplateDesignerStore, { MultiModel } from '../data/template-designer-store';
import { DynamicLayerInput } from '../types/dynamicLayer.type';

class FrameTypeHelpers {
    static MIN_TITLE_LENGTH = 3;
    static MAX_TITLE_LENGTH = 50;
    static DEFAULT_DURATION = 5;
    static MIN_DURATION = 0.1;
    static MAX_DURATION = 30;
    static MAX_DURATION_VIDEO = 300;

    /**
     * Get the minimum duration of the frame type.
     */
    static get minDuration(): number {
        return this.MIN_DURATION;
    }

    /**
     * Get the maximum duration of the frame type.
     */
    static get maxDuration(): number {
        const templateType = getTemplateData<TemplateData['type']>('templateData.type');

        switch (templateType) {
            case 'dynamicVideoDesigned':
                return this.MAX_DURATION_VIDEO;
            default:
                return this.MAX_DURATION;
        }
    }

    /**
     * Select a frame type to view.
     * @param frameTypeKey - Frame type key to view.
     */
    static selectFrameType(frameTypeKey: FrameType['key']): void {
        TemplateDesignerStore.save(
            [
                ['state.selectedLayers', []],
                ['state.selectedFormats', ['general']],
                ['view.frameType', frameTypeKey]
            ],
            { saveToHistory: false }
        );

        TimelineHelpers.seekTo(0);
    }

    /**
     * Add new frame type to the template.
     * @param title - Title of the frame type.
     * @param duration - Duration of the frameType
     */
    static addFrameType(title: FrameType['title'], duration: FrameType['duration'] = this.DEFAULT_DURATION): void {
        const formats = getTemplateData<Template['formats']>('formats');
        const layers = getTemplateData<Template['layers']>('layers');
        const frameTypes = getTemplateData<Template['frameTypes']>('frameTypes');

        const changes: MultiModel = [];

        const newFrameType: FrameType = {
            key: generateKey('frameType'),
            title: title,
            duration: duration
        };

        frameTypes.push(newFrameType);

        layers[newFrameType.key] = [];

        changes.push([`layerProperties.general.${newFrameType.key}`, { properties: defaultFormatProperties }]);

        formats.forEach((format) => {
            changes.push([`layerProperties.${format.key}.${newFrameType.key}`, { properties: {} }]);
        });

        TemplateDesignerStore.save([
            ...changes,
            ['view.frameType', newFrameType.key],
            ['layers', layers],
            ['frameTypes', frameTypes],
            ['state.selectedLayers', []],
            [`state.hiddenLayers.${newFrameType.key}`, {}],
            [`dynamicLayers.${newFrameType.key}`, []],
            [`dataVariables.${newFrameType.key}`, {}]
        ]);
    }

    /**
     * Deletes a frame type from everywhere.
     * @param frameTypeKey - Frame type key to delete.
     */
    static deleteFrameType(frameTypeKey: FrameType['key']): void {
        let frameTypes = getTemplateData<Template['frameTypes']>('frameTypes');
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        const layers = getTemplateData<Template['layers']>('layers');
        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        const dataVariables = getTemplateData<Template['dataVariables']>('dataVariables');
        const frameType = getTemplateData<Template['view']['frameType']>('view.frameType');
        const largeMedia = getTemplateData<State['largeMedia']>('state.largeMedia');

        // Remove frametype from array.
        frameTypes = frameTypes.filter((item) => item.key !== frameTypeKey);

        // Remove frametype styling.
        Object.keys(layerProperties).forEach((prop) => delete layerProperties[prop][frameTypeKey]);

        // Remove frametype from interface setup.
        delete dynamicLayers[frameTypeKey];

        // Remove frametype from interface setup.
        delete dataVariables[frameTypeKey];

        // Remove frametype layers.
        delete layers[frameTypeKey];

        // Remove large media.
        const newLargeMedia = largeMedia?.filter((item) => item.frameType !== frameTypeKey) ?? [];

        const changes: MultiModel = [
            ['state.selectedLayers', [], false],
            ['state.largeMedia', newLargeMedia],
            ['dynamicLayers', dynamicLayers],
            ['dataVariables', dataVariables],
            ['frameTypes', frameTypes],
            ['layerProperties', layerProperties],
            ['layers', layers]
        ];

        if (frameType === frameTypeKey) {
            // If the current frame type is the one being deleted, change the view to the first frame type.
            changes.push(['view.frameType', frameTypes[1].key]);
        }

        const newConflictingAnimations = TimelineHelpers.getNewConflictingAnimations(frameTypeKey);

        changes.push(['state.conflictingAnimations', newConflictingAnimations]);
        TemplateDesignerStore.save(changes);
    }

    /**
     * Duplicates a frame type with al the data.
     * @param frameTypeKey - Frame type key to duplicate.
     */
    static duplicateFrameType = (frameTypeKey: FrameType['key']): void => {
        const frameTypes = getTemplateData<Template['frameTypes']>('frameTypes');
        const layers = getTemplateData<Template['layers']>('layers');
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        const dataVariables = getTemplateData<Template['dataVariables']>('dataVariables');

        // Get the frame type to duplicate.
        const frameTypeToDuplicate = frameTypes.find((frameType) => frameType.key === frameTypeKey);

        if (!frameTypeToDuplicate) {
            throw new Error(Translation.get('general.errors.frameTypeErrors.noFrameTypeFound', 'template-designer'));
        }

        // Create new frame type.
        const newFrameType: FrameType = {
            ...frameTypeToDuplicate,
            title: (() => {
                /**
                 * Check if the title ends with a number.
                 * If it does, increment the number and update the title.
                 * If it doesn't, simply copy the title.
                 */
                const regex = /^(.*?)(\d+)$/;
                const match = frameTypeToDuplicate.title.match(regex);

                let newFrameTypeTitle = frameTypeToDuplicate.title;
                if (match) {
                    // If the title ends with a number, increment the number and update the title
                    const baseTitle = match[1].trimEnd();
                    const number = parseInt(match[2], 10) + 1;
                    newFrameTypeTitle = `${baseTitle} ${number}`;
                } else {
                    // If the title doesn't end with a number, simply copy the title
                    newFrameTypeTitle = frameTypeToDuplicate.title.trimEnd() + ' copy';
                }

                return newFrameTypeTitle;
            })(),
            key: generateKey('frameType')
        };

        /**
         * Create a copy of the original data.
         */
        layers[newFrameType.key] = cloneDeep(layers[frameTypeToDuplicate.key]);
        dynamicLayers[newFrameType.key] = cloneDeep(dynamicLayers[frameTypeToDuplicate.key]);
        dataVariables[newFrameType.key] = cloneDeep(dataVariables[frameTypeToDuplicate.key]);

        Object.keys(layerProperties).forEach((format) => {
            Object.keys(layerProperties[format]).forEach((frameType) => {
                if (frameType !== frameTypeToDuplicate.key) return;

                if (!layerProperties[format][newFrameType.key]) {
                    layerProperties[format][newFrameType.key] = cloneDeep(layerProperties[format][frameType]);
                }
            });
        });

        /**
         * Change the keys of the layer properties.
         * @param layerProperties - The layer properties to change the keys of.
         * @param oldLayerKey - The old layer key.
         * @param newLayerKey - The new layer key.
         */
        const changeLayerPropertiesKeys = (layerProperties: Template['layerProperties'], oldLayerKey: Layer['key'], newLayerKey: Layer['key']) => {
            Object.keys(layerProperties).forEach((format) => {
                Object.keys(layerProperties[format]).forEach((frameType) => {
                    if (frameType !== frameTypeToDuplicate.key) return;

                    Object.keys(layerProperties[format][newFrameType.key]).forEach((layerKey) => {
                        if (oldLayerKey !== layerKey) return;
                        const oldLayerProperties = cloneDeep(layerProperties[format][newFrameType.key][layerKey]);
                        delete layerProperties[format][newFrameType.key][layerKey];
                        layerProperties[format][newFrameType.key][newLayerKey] = oldLayerProperties;
                    });
                });
            });
        };

        /**
         * Change the keys of the interface setup and linked layers.
         * @param inputs - The inputs to change the keys of.
         * @param oldLayerKey - The old layer key.
         * @param newLayerKey - The new layer key.
         */
        const changeDynamicLayerKeys = (inputs: DynamicLayerInput[], oldLayerKey: Layer['key'], newLayerKey: Layer['key']) => {
            inputs.forEach((input) => {
                input.key = generateKey(input.attribute);

                if ('layerKey' in input && input.layerKey === oldLayerKey) {
                    input.layerKey = newLayerKey;
                }

                if ('children' in input && input.children.length) {
                    changeDynamicLayerKeys(input.children, oldLayerKey, newLayerKey);
                }
            });
        };

        /**
         * Change the keys of the feed mapping.
         * @param dataVariables - Feed mapping to change the keys of.
         * @param oldLayerKey - The old layer key.
         * @param newLayerKey - The new layer key.
         */
        const changeFeedMappingKeys = (dataVariables: Template['dataVariables']['main'], oldLayerKey: Layer['key'], newLayerKey: Layer['key']) => {
            Object.keys(dataVariables).forEach((layerKey) => {
                if (oldLayerKey !== layerKey) return;

                const oldLayerData = cloneDeep(dataVariables[layerKey]);
                delete dataVariables[layerKey];
                dataVariables[newLayerKey] = oldLayerData;
            });
        };

        /**
         * Change the keys of the copied data.
         * @param layers - The layers to change the keys of.
         */
        const changeKeys = (layers: Layer[]) => {
            layers.forEach((layer) => {
                const newLayerKey = generateKey(layer.type);

                changeLayerPropertiesKeys(layerProperties, layer.key, newLayerKey);
                changeDynamicLayerKeys(dynamicLayers[newFrameType.key], layer.key, newLayerKey);
                changeFeedMappingKeys(dataVariables[newFrameType.key], layer.key, newLayerKey);

                layer.key = newLayerKey;

                if (layer.children && layer.children.length) {
                    changeKeys(layer.children);
                }
            });
        };

        changeKeys(layers[newFrameType.key]);

        // Save new data.
        TemplateDesignerStore.save([
            ['frameTypes', [...frameTypes, newFrameType]],
            ['layers', layers],
            ['dynamicLayers', dynamicLayers],
            ['layerProperties', layerProperties],
            ['dataVariables', dataVariables],
            ['view.frameType', newFrameType.key],
            ['state.selectedLayers', [], false]
        ]);
    };

    /**
     * Change a frame type property with a new value.
     * @param frameTypeKey - Frame type key to change.
     * @param property - Property to change.
     * @param value - The new value of the given property.
     */
    static changeFrameTypeValue<K extends keyof Omit<FrameType, 'key'>>(frameTypeKey: FrameType['key'], property: K, value: FrameType[K]): void {
        const frameTypes = getTemplateData<Template['frameTypes']>('frameTypes');
        const newFrameTypes = frameTypes.map((item) => {
            if (item.key === frameTypeKey) {
                return {
                    ...item,
                    [property]: value
                };
            }

            return item;
        });

        TemplateDesignerStore.save(['frameTypes', newFrameTypes]);
    }

    /**
     * Moves a frame type in the array to a new spot.
     * @param frameTypeKey - Frame type ket to move in the array.
     * @param places - How many places to move.
     */
    static moveFrameType = (frameTypeKey: FrameType['key'], places: number): void => {
        const frameTypes = getTemplateData<Template['frameTypes']>('frameTypes');

        const from = frameTypes.findIndex((frameType) => frameType.key === frameTypeKey);
        const to = from + places;

        const tempFrameType = frameTypes.splice(from, 1)[0];
        frameTypes.splice(to, 0, tempFrameType);

        TemplateDesignerStore.save(['frameTypes', frameTypes]);
    };

    /**
     * Gets the current duration of the frame.
     * @param frameType - The frame type to get the duration of.
     */
    static getFrameDuration = (frameType?: View['frameType'], frameTypes?: Template['frameTypes']): number => {
        if (!frameType) frameType = getTemplateData<View['frameType']>('view.frameType');
        if (!frameTypes) frameTypes = getTemplateData<Template['frameTypes']>('frameTypes');
        return frameTypes.find((ft) => ft.key === frameType)?.duration ?? this.DEFAULT_DURATION;
    };
}

export default FrameTypeHelpers;
